:root{
  --normalFont:calc(3rem + .4rem);
  --tinyFont:calc(.4rem + .4rem);
  --plsBlue:#005588;
  --plsGreen:#4EC0AC;
  --headerHeight:50px;
  --AppGap:10px;
}
*{
  padding:0;
  margin:0;
  box-sizing: border-box;
 
}
.App{
background-color:#ECF0F1;

}
.hand:hover{
cursor:pointer
}
.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
font-size:calc(.9rem + .2vw);

}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  border-radius: 5px 5px 0 0;

}
.react-tabs__tab {
  margin:5px 1.5px;
  border:2px solid white;
  border-radius: 7px 7px 0px 0px;
}

.hand:hover{
  cursor:pointer
  }
